<template>
    <section class="bg-login-cargando">
        <div class="row justify-content-center w-100">
            <div class="col-10 col-sm-8 col-md-7 col-lg-5 col-xl-5 text-center">
                <p class="text-5d f-20 f-500 pl-2"> ¡Acceso denegado! </p>
                <img src="/img/generales/denegado.svg" alt="" class="img-logo heartbeat" />
                <p class="text-5d f-20 f-500 pl-2"> Este usuario no tiene acceso a esta dirección. </p>
            </div>
            <div class="row justify-content-center w-100">
                <div class="col-12 pt-3 col-sm-8 col-md-7 col-lg-5 col-xl-5 text-center">
                    <button class="btn btn-general h-32px f-14 f-300 px-4" @click="volver">
                        Regresar
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data(){
        return{
            
        }
    },
    methods:{
        volver(){
            this.$router.push({ name: 'zonas.historial' }, 5000)
        }
    }
    
}
</script>
<style lang="scss" scoped>
.bg-login-cargando{
    background-size: cover;
    background-position-x: right;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-logo{
        width: 250px;
        height: 250px;
    }
}
</style>